import { useContext } from 'react';
import FixtureCard from '../fixtures/fixture-card';
import { Context } from '@/context';

const HomeCalendarioComponent = () => {
    const { state } = useContext(Context);
    const { competitions } = state;
    const getFixtures = () => {
        if (competitions?.fixtures?.length > 0) {
            return competitions?.fixtures?.map((fixture, index) => (
                <div key={index} className="fixture ">
                    <FixtureCard
                        date={fixture?.date}
                        fixtureId={fixture?.fixtureId}
                        leagueId={fixture?.league}
                        round={fixture?.round}
                        home={fixture?.home}
                        away={fixture?.away}
                        venue={fixture?.venue}
                        referee={fixture?.referee}
                        homeGoal={fixture?.home?.goals}
                        awayGoal={fixture?.away?.goals}
                    />
                </div>
            ));
        }
    };
    return <div>{getFixtures()}</div>;
};

export default HomeCalendarioComponent;
