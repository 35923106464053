const ClosedIcon = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 16}
            height={height ?? width ?? 16}
            viewBox="0 0 36 36"
        >
            <path
                fill="#AAB8C2"
                d="M18 3C12.477 3 8 7.477 8 13v10h4V13a6 6 0 0 1 12 0v10h4V13c0-5.523-4.477-10-10-10"
            />
            <path
                fill="#FFAC33"
                d="M31 32a4 4 0 0 1-4 4H9a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4z"
            />
        </svg>
    );
};

export default ClosedIcon;
