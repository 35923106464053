import { useEffect, useState, useRef } from 'react';
import ButtonMaterial from '../shared/custom/button';
import { PostCard } from './post-card';

const HomeNoticiasComponent = ({ noticias }) => {
    const [localNoticias, setLocalNoticias] = useState(noticias);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        setLocalNoticias(noticias);
    }, [noticias]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    setContainerWidth(entry.contentRect.width);
                }
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    const getNoticias = () => {
        return localNoticias?.map((post, index) => (
            <div key={index} className="noticias">
                <PostCard
                    author={post.author?.name}
                    authorName={post.author?.name}
                    cover={post.image ?? post.cover}
                    publishedAt={post.publishedAt}
                    title={post.title}
                    slug={post.slug}
                    width={containerWidth}
                    url={post.url}
                />
            </div>
        ));
    };

    return (
        <div className="noticias-component" ref={containerRef}>
            {getNoticias()}
            <div>
                <ButtonMaterial
                    text="Ver más noticias en analiticafantasy.com"
                    variant={'outlined'}
                    styles={{ width: '100%' }}
                    onClick={() => window.open(process.env.NEXT_PUBLIC_URL_MASTER_SITE, '_blank')}
                />
            </div>
        </div>
    );
};

export default HomeNoticiasComponent;
