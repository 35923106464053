const LiveIcon = ({ width, height, color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 16}
            height={height ?? width ?? 16}
            viewBox="0 0 72 72"
        >
            <circle cx="36" cy="36" r="28" fill={color ?? '#d22f27'} />
        </svg>
    );
};

export default LiveIcon;
