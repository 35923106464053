import { useRouter } from 'next/router';
import { useContext } from 'react';
import { Skeleton } from '@mui/material';
import ButtonMaterial from '../shared/custom/button';
import CustomImageComponent from '../shared/custom/image';
import { Context } from '@/context';
import URL_FACTORY from '@/http/url-helper';
import axiosApiInstance from '@/http/axios-instance';
import { notifyError, notifySuccess, notifyWarning } from '@/notifications/notify';
import { STATUS_CODE } from '@/constants/status-code';
import { REDUCERS } from '@/constants/reducers';
import { RUTAS } from '@/constants/rutas';
import NextIcon from '@/icons/competitions-status/next-icon';
import LiveIcon from '@/icons/competitions-status/live-icon';
import { COMPETITION_STATUS } from '@/constants/competition-status';
import ClosedIcon from '@/icons/competitions-status/closed-icon';
import FinishedIcon from '@/icons/competitions-status/finished-icon';
import ComingSoonIcon from '@/icons/competitions-status/coming-soon-icon';

const HomeEventsComponent = () => {
    const { state, dispatch } = useContext(Context);
    const { competitions, user } = state;
    const router = useRouter();

    const getCompetitionStatus = (competition) => {
        switch (competition.status) {
            case COMPETITION_STATUS.Published:
                return (
                    <div className="events__status-left">
                        <NextIcon />
                        <span>Próximamente</span>
                    </div>
                );
            case COMPETITION_STATUS.Open:
                return (
                    <div className="events__status-left">
                        <ComingSoonIcon />
                        <span>Comienza pronto</span>
                    </div>
                );
            case COMPETITION_STATUS.InProgress:
                return (
                    <div className="events__status-left">
                        <LiveIcon />
                        <span>En juego</span>
                    </div>
                );
            case COMPETITION_STATUS.Finished:
                return (
                    <div className="events__status-left">
                        <FinishedIcon />
                        <span>Finalizado</span>
                    </div>
                );
            case COMPETITION_STATUS.Closed:
                return (
                    <div className="events__status-left">
                        <ClosedIcon />
                        <span>Cerrado</span>
                    </div>
                );
        }
    };

    const handleJoinCompetition = (competition) => {
        if (!user.logged) {
            notifyWarning('Debes iniciar sesión para unirte a la competición');
            return;
        }
        const url = URL_FACTORY.JoinCompetitions(competition.id);

        axiosApiInstance
            .get(url)
            .then(() => {
                notifySuccess('Te has unido a la competición correctamente');
                dispatch({
                    type: REDUCERS.UPDATE_COMPETITIONS,
                    payload: {
                        ...competitions,
                        shouldUpdate: !competitions.shouldUpdate,
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === STATUS_CODE.CONFLICT) {
                    notifyError('Te has unido a la competición correctamente');
                } else {
                    notifyError('Ha ocurrido un error al unirte a la competición');
                }
            });
    };

    const getButtonJoinCompetition = (competition) => {
        if (!competition.userJoined) {
            return (
                <ButtonMaterial
                    text="Únete a la liga"
                    variant="outlined"
                    onClick={() => handleJoinCompetition(competition)}
                />
            );
        } else {
            return <ButtonMaterial text="Ya eres miembro" variant="outlined" disabled={true} />;
        }
    };

    const getVotarBtn = (competition) => {
        if (competition.userJoined && competition.status === COMPETITION_STATUS.InProgress) {
            return (
                <ButtonMaterial
                    text="Votar"
                    color={'primary'}
                    styles={{ width: '157px' }}
                    onClick={() => {
                        router.push(`${RUTAS.VOTACIONES}/${competition.slug}`);
                    }}
                />
            );
        }
    };

    const getCompetitions = () => {
        if (competitions.competitions?.length > 0) {
            return competitions.competitions?.map((competicion, index) => (
                <div key={index} className="events__card">
                    <div className="events__content">
                        <div className="events__image-left">
                            <CustomImageComponent
                                src={`${competicion.image}?width=105&height=105`}
                                alt={competicion.name}
                                width={70}
                                height={70}
                            />
                        </div>
                        <div className="events__description">
                            <p className="events__title">{competicion.name}</p>
                            <p className="events__total">Usuarios: {competicion.users}</p>
                            <p className="events__date">Jornadas: {competicion.jornadas}</p>
                        </div>
                    </div>
                    <p className="events__text">{competicion.description}</p>
                    <div className="events__status">
                        {getCompetitionStatus(competicion)}
                        {getVotarBtn(competicion)}
                    </div>
                    <div className="events__actions">
                        <ButtonMaterial
                            text="Ver clasificación"
                            variant="outlined"
                            color={'secondary'}
                            onClick={() => {
                                router.push(`${RUTAS.QUINIELA_CLASIFICACION}/${competicion.slug}`);
                            }}
                        />
                        {getButtonJoinCompetition(competicion)}
                    </div>
                </div>
            ));
        } else {
            return (
                <div>
                    <Skeleton
                        style={{ marginBottom: '24px' }}
                        variant="rectangular"
                        width="100%"
                        height={280}
                    />
                    <Skeleton
                        style={{ marginBottom: '24px' }}
                        variant="rectangular"
                        width="100%"
                        height={280}
                    />
                    <Skeleton
                        style={{ marginBottom: '24px' }}
                        variant="rectangular"
                        width="100%"
                        height={280}
                    />
                </div>
            );
        }
    };

    return <div className="events">{getCompetitions()}</div>;
};

export default HomeEventsComponent;
