const NextIcon = ({ width, height, color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 16}
            height={height ?? width ?? 16}
            viewBox="0 0 24 24"
        >
            <path
                fill={color ?? '#000000'}
                d="M15 22v-2h4V10H5v4H3V6q0-.825.588-1.412T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm-7 2l-1.4-1.4L9.175 20H1v-2h8.175L6.6 15.4L8 14l5 5zM5 8h14V6H5zm0 0V6z"
            />
        </svg>
    );
};

export default NextIcon;
