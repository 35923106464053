export const STATUS_CODE = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    GONE: 410,
    PRECONDITION_FAILED: 412,
    TOO_MANY_ATTEMPTS: 429,
    FAILED_EXPECT_CONDITIONS: 417,
    INTERNAL_SERVER_ERROR: 500,
};
