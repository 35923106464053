import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import NoticiasFlashButton from '../noticias-flash/noticias-flash-button';
import InputMaterial from '../shared/custom/input';
import CustomImageComponent from '../shared/custom/image';
import NoticiasFlashIcon from './noticias-flash-icon';
import { getPlayerIcon, getPlayersStatus, replaceDiacritis } from '@/helpers/players-helper';
import { getMasterPlayerImage } from '@/helpers/images-helper';
import { RUTAS } from '@/constants/rutas';
import { getDateMMddYYYYHHmm } from '@/helpers/datetime-helper';
import CalendarIcon from '@/icons/calendar-icon';
import ReportIcon from '@/icons/reporte-icon';
import RightArrow from '@/icons/right-arrow';
import { getTeamImageById } from '@/data/teams';

const HomeFlashList = ({ hideHeader, buttonText, header, hideSearch, noticiasFlash, cssClass }) => {
    const [noticias, setNoticias] = useState(noticiasFlash ?? []);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setNoticias(noticiasFlash);
    }, [noticiasFlash]);

    const filterNoticias = (title, search) => {
        if (!title || !search) return true;
        return replaceDiacritis(title).indexOf(replaceDiacritis(search)) > -1;
    };

    const groupNoticiasByFecha = () => {
        const groupedNoticias = {};
        noticias
            ?.filter((noticia) => filterNoticias(noticia?.player?.name, search))
            ?.forEach((noticia) => {
                const fecha = getDateMMddYYYYHHmm(noticia.created).split(' ')[0];
                if (!groupedNoticias[fecha]) {
                    groupedNoticias[fecha] = [];
                }
                groupedNoticias[fecha].push(noticia);
            });
        return groupedNoticias;
    };

    const getNoticiaFlashCard = (noticia, index) => {
        return (
            <div key={index} className="flash-list-card">
                <div className="flash-list-card__info">
                    <CustomImageComponent
                        src={getMasterPlayerImage(noticia.player, 40)}
                        width="40"
                        height="40"
                        alt={'jugador'}
                    />
                    <div className="flash-list-card__top">
                        <div className="flash-list-card__top-section">
                            <p className="flash-list-card__name">{noticia.player.name}</p>
                            <div className="flash-list-card__team-image">
                                <CustomImageComponent
                                    src={getTeamImageById(noticia.player.teamId, 30)}
                                    width="20"
                                    height="20"
                                    alt={'noticia'}
                                />
                                <p style={{ marginBottom: 0 }}>{noticia.player.teamName}</p>
                            </div>
                        </div>
                        <div className="flash-list-card__top-section flash-list-card__top-section-right">
                            <p className="flash-list-card__updated">{getDateMMddYYYYHHmm(noticia.created)}</p>
                            <p className="flash-list-card__link">
                                <ReportIcon iconWidth={15} iconHeight={15} />
                                Noticia flash
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flash-list-card__status">
                    <div className="general-transfer-status">
                        <div className="general-transfer-status__info">
                            {getPlayerIcon(noticia?.previous?.status, noticia?.previous?.statusLevel)}
                            {getPlayersStatus(noticia?.previous?.status, noticia?.previous?.statusLevel)}
                        </div>
                        <div>
                            <RightArrow />
                        </div>
                        <div className="general-transfer-status__info">
                            {getPlayerIcon(
                                noticia?.generalStatus?.status,
                                noticia?.generalStatus?.statusLevel
                            )}
                            {getPlayersStatus(
                                noticia?.generalStatus?.status,
                                noticia?.generalStatus?.statusLevel
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const groupedNoticias = groupNoticiasByFecha();

    const handleLoadFlash = () => {
        window.open(process.env.NEXT_PUBLIC_URL_MASTER_SITE + RUTAS.NOTICIAS_FLASH, '_blank');
    };

    const getNoticiasflashComponent = () => {
        if (noticias?.length > 0) {
            return Object.keys(groupedNoticias).map((fecha, index) => (
                <div key={index} className="fecha-div">
                    <div className="fecha">
                        <div>
                            <CalendarIcon iconWidth={15} iconHeight={15} color={'#000'} />
                            {fecha}
                        </div>
                        <div>
                            <span>{groupedNoticias[fecha].length}</span>
                            <NoticiasFlashIcon color={'#000'} iconHeight={15} iconWidth={15} />
                        </div>
                    </div>
                    {groupedNoticias[fecha].map((noticia, cardIndex) =>
                        getNoticiaFlashCard(noticia, cardIndex)
                    )}
                </div>
            ));
        }

        return (
            <div>
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
            </div>
        );
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    return (
        <div className={`${cssClass} flash-list`}>
            {noticias?.length > 0 && hideHeader !== true && (
                <p className="flash-list__header">{header ?? 'Últimas noticias flash'}</p>
            )}

            {hideSearch !== true && (
                <div className="mb-25">
                    <InputMaterial label="Nombre" onChange={handleSearch} value={search} />
                </div>
            )}

            {getNoticiasflashComponent()}

            {noticias?.length > 0 && (
                <div className="mb-25">
                    <NoticiasFlashButton
                        text={buttonText ?? 'Ver más flash en analiticafantasy.com'}
                        onClick={handleLoadFlash}
                    />
                </div>
            )}
        </div>
    );
};

export default HomeFlashList;
