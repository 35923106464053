import HomeComponent from '@/components/home/home-component';
import SeoComponent from '@/components/shared/seo/seo-component';
import { Pages } from '@/constants/rutas';
import axiosApiInstance from '@/http/axios-instance';
import URL_FACTORY from '@/http/url-helper';

export default function HomePage({ initialNoticias, initialFlash }) {
    return (
        <section className="home-page">
            <SeoComponent pageId={Pages.INICIO} />
            <div className="container home-page">
                <h1 className="home-page__title">Quiniela Fantasy</h1>
                <HomeComponent initialNoticias={initialNoticias} initialFlash={initialFlash} />
            </div>
        </section>
    );
}

// Agregar ISR con getStaticProps
export async function getStaticProps() {
    try {
        const noticiasResponse = await axiosApiInstance.post(URL_FACTORY.GetPublishedArticles, {
            skip: 0,
            take: 10,
        });

        const flashResponse = await axiosApiInstance.post(URL_FACTORY.GetNoticiasFlash, {
            skip: 0,
            take: 10,
            ignore: '',
            teamSlug: '',
        });

        return {
            props: {
                initialNoticias: noticiasResponse.data?.articles || [],
                initialFlash: flashResponse.data?.noticias || [],
            },
            revalidate: 60, // Revalidar cada 60 segundos
        };
    } catch (error) {
        console.error('Error fetching initial data for ISR:', error);
        return {
            props: {
                initialNoticias: [],
                initialFlash: [],
            },
            revalidate: 60, // Asegura que la página sigue revalidándose
        };
    }
}
