import { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import HomeNoticiasComponent from './home-noticias';
import HomeFlashList from './home-flash-list';
import HomeCalendarioComponent from './home-calendario';
import NoticiasFlashIcon from './noticias-flash-icon';
import HomeEventsComponent from './home-events';
import URL_FACTORY from '@/http/url-helper';
import axiosApiInstance from '@/http/axios-instance';
import NewsIcon from '@/icons/news-icon';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import CalendarIcon from '@/icons/calendar-icon';

const HomeComponent = ({ initialNoticias = [], initialFlash = [] }) => {
    const { dispatch } = useContext(Context);
    const [noticias, setNoticias] = useState(initialNoticias);
    const [noticiasFlash, setNoticiasFlash] = useState(initialFlash);
    const [currentTab, setCurrentTab] = useState(1);
    const skip = 0;
    const take = 10;

    const handleChange = (index) => {
        setCurrentTab(index);
    };

    useEffect(() => {
        // Cargar noticias solo si no están ya en el estado local
        if (noticias.length === 0) {
            const payload = { skip, take };
            axiosApiInstance.post(URL_FACTORY.GetPublishedArticles, payload).then((response) => {
                const fetchedNoticias = response.data?.articles || [];
                setNoticias(fetchedNoticias); // Actualizar el estado local
                dispatch({
                    type: REDUCERS.UPDATE_CONTENT,
                    payload: { noticias: fetchedNoticias }, // Actualizar el estado global
                });
            });
        }
    }, [noticias.length, dispatch]);

    useEffect(() => {
        // Cargar noticias Flash solo si no están en el estado local y la pestaña activa es Flash
        if (noticiasFlash.length === 0 && currentTab === 2) {
            const payload = { skip: 0, take, ignore: '', teamSlug: '' };
            axiosApiInstance
                .post(URL_FACTORY.GetNoticiasFlash, payload)
                .then((response) => {
                    const fetchedFlash = response.data?.noticias || [];
                    setNoticiasFlash(fetchedFlash); // Actualizar el estado local
                    dispatch({
                        type: REDUCERS.UPDATE_CONTENT,
                        payload: { flash: fetchedFlash }, // Actualizar el estado global
                    });
                })
                .catch((error) => {
                    console.error('Error fetching flash news:', error);
                });
        }
    }, [currentTab, noticiasFlash.length, dispatch]);

    const tabs = [
        {
            index: 1,
            text: 'Noticias',
            component: <HomeNoticiasComponent noticias={noticias} />,
            icon: <NewsIcon color="#fff" />,
        },
        {
            index: 2,
            text: 'Flash',
            component: (
                <HomeFlashList
                    take={take}
                    header=""
                    hideHeader={false}
                    hideSearch={true}
                    noticiasFlash={noticiasFlash}
                    cssClass="display-only-desktop"
                />
            ),
            icon: <NoticiasFlashIcon color="#fff" />,
        },
        {
            index: 3,
            text: 'Calendario',
            component: <HomeCalendarioComponent />,
            icon: <CalendarIcon color="#fff" />,
        },
    ];

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-8">
                    <HomeEventsComponent />
                </div>
                <div className="col-12 col-md-6 col-lg-4" id="home-tabs">
                    <div className="af-tab-list af-light-tab-list">
                        {tabs.map((tab) => (
                            <div
                                key={tab.index}
                                className={`af-tab ${currentTab === tab.index && 'af-tab-selected'}`}
                                onClick={() => handleChange(tab.index)}
                            >
                                <Typography className="af-tab-text">{tab.text}</Typography>
                                {tab.icon}
                            </div>
                        ))}
                    </div>
                    <div>
                        {tabs.map((tab) => (
                            <div key={tab.index}>{currentTab === tab.index && tab.component}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeComponent;
