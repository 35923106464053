const defaultImageSrc = `${process.env.NEXT_PUBLIC_IMAGES}/quiniela/quiniela-fantasy.png`;

export const imageUrlChecker = (url) => {
    return url.replace('https://futbolstats.blob.core.windows.net', process.env.NEXT_PUBLIC_IMAGES);
};

export const getArticleImage = (articulo) => {
    if (articulo?.coverImage) {
        return imageUrlChecker(articulo?.coverImage);
    }

    if (articulo?.bannerImage) {
        return imageUrlChecker(articulo?.bannerImage);
    }

    return defaultImageSrc;
};

export const getPostCardImage = (cover) => {
    if (cover) {
        return imageUrlChecker(cover);
    }

    return defaultImageSrc;
};

export const getMasterPlayerImage = (player, width, isDefault) => {
    const addWidth = width ? `?width=${width}&height=${width}` : '';

    if (player?.information?.photo) {
        return `${player?.information?.photo}${addWidth}`;
    }

    if (player?.photo) {
        return `${player?.photo}${addWidth}`;
    }

    if (isDefault && player.id) {
        return `${process.env.NEXT_PUBLIC_DEFAULT_IMAGES}/${player.id}.png${addWidth}`;
    }

    const id = player?.id;

    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getPlayerPhotoForceId = (player, width) => {
    const addWidth = width ? `?width=${width}&height=${width}` : '';
    const id = player?.information?.id;
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getFantasyPlayerImage = (id, width) => {
    const addWidth = width ? `?width=${width}&height=${width}` : '';
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores-fantasy/${id}.png${addWidth}`;
};

export const getMasterPhotoById = (id, width) => {
    const addWidth = width ? `?width=${width}&height=${width}` : '';
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getDefaultPlayerPhoto = (width) => {
    const addWidth = width ? `?width=${width}&height=${width}` : '';
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png${addWidth}`;
};

export const getDefaultTeamPhoto = (width) => {
    const addWidth = width ? `?width=${width}&height=${width}` : '';
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png${addWidth}`;
};
