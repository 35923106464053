const ReportIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 15}
            height={iconHeight ?? 15}
            viewBox="0 0 24 24"
        >
            <path
                fill="#0284c7"
                d="M12 19q1.675 0 2.838-1.175T16 15v-4h-2v4q0 .825-.575 1.413T12 17q-.825 0-1.413-.588T10 15V9.5q0-.225.15-.363T10.5 9q.225 0 .363.138T11 9.5V15h2V9.5q0-1.05-.725-1.775T10.5 7q-1.05 0-1.775.725T8 9.5V15q0 1.65 1.175 2.825T12 19Zm-8 3V2h11l5 5v15H4Zm2-2h12V8h-4V4H6v16ZM6 4v4v-4v16V4Z"
            />
        </svg>
    );
};

export default ReportIcon;
