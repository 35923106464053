import { toast } from 'react-toastify';

const notifyError = (message, delay) => {
    toast.error(message, {
        position: 'top-right',
        autoClose: delay ? delay * 1000 : 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
};

const notifySuccess = (message, delay) => {
    toast.success(message, {
        position: 'top-right',
        autoClose: delay ? delay * 1000 : 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
};

const notifyWarning = (message, delay) => {
    toast.warn(message, {
        position: 'top-right',
        autoClose: delay ? delay * 1000 : 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
};

export { notifyError, notifySuccess, notifyWarning };
