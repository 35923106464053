const ComingSoonIcon = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 16}
            height={height ?? width ?? 16}
            viewBox="0 0 36 36"
        >
            <path
                fill="#31373D"
                d="M35.311 6.652L25.17.263c-.904-.569-1.645-.181-1.645.865V3H2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h21.525v1.899c0 1.046.723 1.413 1.607.811l10.215-6.931c.885-.6.868-1.557-.036-2.127M6.303 27.263c0 .537-.357 1.018-.921 1.018c-.563 0-1.002-.412-1.826-.412c-.591 0-1.126.316-1.126.893c0 1.414 4.587.509 4.587 4.052C7.017 34.776 5.396 36 3.501 36c-1.058 0-3.337-.248-3.337-1.539c0-.535.356-.976.92-.976c.645 0 1.415.536 2.308.536c.907 0 1.401-.509 1.401-1.182c0-1.62-4.588-.645-4.588-3.832c0-1.923 1.58-3.118 3.407-3.118c.768.001 2.691.289 2.691 1.374m1.213 3.681c0-2.855 1.922-5.055 4.725-5.055c2.761 0 4.725 2.268 4.725 5.055c0 2.844-1.91 5.056-4.725 5.056c-2.788 0-4.725-2.212-4.725-5.056m7.307 0c0-1.661-.866-3.159-2.583-3.159s-2.582 1.498-2.582 3.159c0 1.676.838 3.159 2.582 3.159c1.745.001 2.583-1.483 2.583-3.159m2.615 0c0-2.855 1.923-5.055 4.725-5.055c2.76 0 4.725 2.268 4.725 5.055c0 2.844-1.909 5.056-4.725 5.056c-2.789 0-4.725-2.212-4.725-5.056m7.306 0c0-1.661-.864-3.159-2.581-3.159c-1.718 0-2.582 1.498-2.582 3.159c0 1.676.838 3.159 2.582 3.159c1.743.001 2.581-1.483 2.581-3.159m2.918-3.9c0-.688.44-1.072 1.03-1.072c.261 0 .687.206.865.454l4.353 5.81h.028v-5.191c0-.688.439-1.072 1.031-1.072c.589 0 1.029.385 1.029 1.072v7.802c0 .688-.44 1.072-1.029 1.072c-.263 0-.675-.206-.866-.454l-4.354-5.741h-.027v5.123c0 .688-.44 1.072-1.03 1.072s-1.03-.385-1.03-1.072z"
            />
        </svg>
    );
};

export default ComingSoonIcon;
