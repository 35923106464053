const NoticiasFlashIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 48 48"
        >
            <path fill="#FFC107" d="M33 22h-9.4L30 5H19l-6 21h8.6L17 45z" />
            <path fill="#F44336" d="M40.8 14.5h-4.3l-.9 2.5H33l4.5-12h2.3l4.5 12h-2.6zm-3.7-2h3L38.6 8z" />
        </svg>
    );
};

export default NoticiasFlashIcon;
