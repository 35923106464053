import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomImageComponent from '../shared/custom/image';
import { RUTAS } from '@/constants/rutas';
import { getPostCardImage } from '@/helpers/images-helper';
import { getDateMMddYYYYHHmm } from '@/helpers/datetime-helper';
import { getAuthorLogo } from '@/helpers/author-helper';

export const PostCard = ({ author, authorName, cover, publishedAt, title, slug, url, width, ...other }) => {
    let formattedPublishedAt = getDateMMddYYYYHHmm(publishedAt);

    const getUrlToRedirect = () => {
        if (url) {
            window.open(url, '_blank');
        }

        window.open(`${process.env.NEXT_PUBLIC_URL_MASTER_SITE}${RUTAS.NOTICIA}/${slug}`, '_blank');
    };

    return (
        <Card
            {...other}
            style={{
                padding: 0,
                height: 'auto',
                boxShadow: 'none',
                borderBottom: '1px solid rgba(0,0,0,.1)',
                marginBottom: '20px',
            }}
            onClick={() => getUrlToRedirect()}
        >
            <CustomImageComponent
                src={getPostCardImage(cover) + '?width=' + (Number(width) * 1.5) + '&height=345'}
                alt="Imagen de la noticia"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: '100%' }}
                title="Imagen de la noticia"
                priority={true}
            />
            <CardContent style={{ padding: '15px 0px' }}>
                <h2
                    style={{
                        fontSize: '20px',
                        textDecoration: 'none',
                        fontWeight: '600',
                        lineHeight: '1.25',
                        marginBottom: '20px',
                    }}
                >
                    {title}
                </h2>
                <div sx={{ mt: 2 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        spacing={2}
                        sx={{ mt: 2 }}
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div style={{ width: '100%', display: 'flex', gap: '2px' }}>
                            <span
                                variant="subtitle2"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontSize: '12px',
                                }}
                            >
                                <span style={{ textTransform: 'uppercase' }}>
                                    <CustomImageComponent
                                        width={30}
                                        height={30}
                                        src={getAuthorLogo(author, 30)}
                                        alt={`logo`}
                                        style={{ marginRight: '8px', borderRadius: '50%' }}
                                    />
                                    {authorName}
                                </span>
                                <span style={{ marginLeft: '8px', marginRight: '8px' }}>•</span>
                                <span>{formattedPublishedAt}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
