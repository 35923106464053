import { PLAYER_STATUS } from '@/constants/player-constants';
import CheckIcon from '@/icons/check';
import AvailableStatusIcon from '@/icons/status-icons/available-status-icon';
import ConvocadoIcon from '@/icons/status-icons/convocado-icon';
import DudaLeveStatusIcon from '@/icons/status-icons/duda-leve-status-icon';
import DudaStatusIcon from '@/icons/status-icons/duda-status-icon';
import ExtradeportivoIcon from '@/icons/status-icons/extradeportivo-icon';
import InjuredStatusIcon from '@/icons/status-icons/injured-status-icon';
import SancionadosIcon from '@/icons/status-icons/sancionados-icon';

export const replaceDiacritis = (text) => {
    if (!text) return text;
    const diacriticsMap = {
        à: 'a',
        á: 'a',
        â: 'a',
        ã: 'a',
        ä: 'a',
        å: 'a',
        æ: 'ae',
        ç: 'c',
        č: 'c',
        ć: 'c',
        è: 'e',
        é: 'e',
        ê: 'e',
        ë: 'e',
        ì: 'i',
        í: 'i',
        î: 'i',
        ï: 'i',
        ð: 'd',
        ò: 'o',
        ó: 'o',
        ô: 'o',
        õ: 'o',
        ö: 'o',
        ø: 'o',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ü: 'u',
        ý: 'y',
        þ: 'th',
        ÿ: 'y',
        ć: 'c',
    };

    return text
        .replace(/[àáâãäåæçèéêëìíîïðòóôõöøùúûüýþÿ]/g, (match) => {
            return diacriticsMap[match];
        })
        .toLowerCase();
};

export const getPlayersStatus = (status, statusLevel) => {
    switch (status) {
        case PLAYER_STATUS.INJURED:
            switch (statusLevel) {
                case 3:
                    return 'Extradeportivo';
                case 1:
                    return 'Lesionado';
                case 2:
                    return 'Lesionado';
                default:
                    return 'Lesionado';
            }
        case PLAYER_STATUS.DOUBTFUL:
            switch (statusLevel) {
                case 2:
                    return 'Duda';
                case 1:
                default:
                    return 'Duda';
            }

        case PLAYER_STATUS.SANCTIONED:
            return 'Sancionado';
        case PLAYER_STATUS.CONVOCADO:
            return 'Convocado';
        case PLAYER_STATUS.NO_CONVOCADO:
            return 'Disponible';
        case PLAYER_STATUS.OK:
        default:
            return 'Disponible';
    }
};

export const getPlayerIcon = (playerStatus, statusLevel, iconWidth, iconHeight) => {
    switch (playerStatus?.toLowerCase()) {
        case PLAYER_STATUS.INJURED:
            switch (statusLevel) {
                case 2:
                    return (
                        <InjuredStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#22272F"
                        />
                    );
                case 3:
                    return <ExtradeportivoIcon iconHeight={iconWidth ?? 18} iconWidth={iconHeight ?? 18} />;
                case 1:
                default:
                    return (
                        <InjuredStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#e11d48"
                        />
                    );
            }
        case PLAYER_STATUS.SANCTIONED:
            return <SancionadosIcon iconHeight={18} iconWidth={16} />;
        case PLAYER_STATUS.DOUBTFUL:
            switch (statusLevel) {
                case 2:
                    return (
                        <DudaStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#ea580c"
                        />
                    );
                case 1:
                default:
                    return (
                        <DudaLeveStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#059669"
                        />
                    );
            }
        case PLAYER_STATUS.CONVOCADO:
            return <ConvocadoIcon height={20} width={20} />;
        case PLAYER_STATUS.NO_CONVOCADO:
            return <CheckIcon height={20} width={20} />;
        case PLAYER_STATUS.OK:
        default:
            return <AvailableStatusIcon />;
    }
};

export const getScoreColor = (score, isAvailable, isTransparent) => {
    if (isTransparent) {
        return '#007252';
    }

    if (score === null || score === undefined || !isAvailable) {
        return '#ccc';
    }

    const scoreNumber = Number(score);

    if (scoreNumber >= 8) {
        return '#1d6fb1';
    } else if (scoreNumber >= 5) {
        return '#1a892d';
    } else if (scoreNumber >= 3) {
        return '#103b17';
    } else if (scoreNumber >= 0) {
        return 'rgb(216, 136, 17)';
    } else {
        return '#be1212';
    }
};

export const getPlayerPositionColor = function (positionId) {
    switch (positionId) {
        case -1:
            return '';
        case 1:
            return 'po';
        case 2:
            return 'df';
        case 3:
            return 'mc';
        case 5:
            return 'dt';
        case 11:
            return 'to';
        default:
            return 'dl';
    }
};

export const getPlayerPositionShort = function (positionId) {
    switch (positionId) {
        case -1:
            return '';
        case 1:
            return 'PO';
        case 2:
            return 'DF';
        case 3:
            return 'MC';
        case 5:
            return 'DT';
        case 11:
            return 'TO';
        default:
            return 'DL';
    }
};
